/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import CookieConsent from 'react-cookie-consent';

import Header from "./header"
import "../styles/layout.scss"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      {/*<Header siteTitle={data.site.siteMetadata.title} />*/}
      {/*<div className='layout-guide' />*/}
      <main className='main'>{children}</main>
      <CookieConsent
        location="top"
        buttonText="OK"
        cookieName="myAwesomeCookieName2"
        style={{ display: "block", fontFamily: "'Open Sans', Arial, 'sans serif'", padding: "15px", background: "white", color: "#556B92", fontSize: "12px", textAlign: "center", lineHeight: "12px", borderBottom: "1px solid #e5e5e5" }}
        buttonStyle={{ background: "white", fontSize: "12px", color: "#556B92", border: "1px solid #556B92", margin: "15px 0 0", minWidth: "70px", outline: "none" }}
        contentStyle={{ margin: 0, display: "block" }}
        expires={365}
      >
        We use cookies to analyze user behavior and marketing activities in a pseudonymized form (conversion tracking, analysis) and to show you personalized advertisements on third-party websites (retargeting). By continuing, you accept the use of cookies and agree to the measures previously mentioned. Please see our privacy statement for further information.<br/><br/><b>Disclaimer:</b> The information and resources included herein are intended solely for audiences located outside of the United States. This may include information about products or features not available or not approved for use in the United States. If you wish to view information intended for audiences located in the United States please <a style={{ textDecoration: "none", color: "#556B92", fontWeight: "bold" }} href="https://www.orsiro.com/us">click here</a>.
      </CookieConsent>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
